<template>
  <v-menu
    v-model="isOpen"
    ref="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        :label="label"
        readonly

        v-bind="{...$attrs, ...attrs}"
        v-on="on"
        @click:clear="onTimeSelect('')"
      >
        <template v-slot:append>
          <v-icon v-on="on">
            mdi-clock-outline
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-time-picker
      :value="value"
      :format="format"
      full-width
      @input="onTimeSelect"
    >
      <v-spacer/>
      <v-btn
        text
        color="primary"
        @click="isOpen = false"
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'TimePicker',
  props: {
    label: String,
    value: {
      type: [String, null],
    },
    format: {
      type: String,
      default: '24hr'
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    onTimeSelect(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
