<template>
  <v-card>
    <v-card-text class="text-center">
      Brak danych
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NoData'
}
</script>

<style scoped>

</style>
