<template>
  <ModalTemplate
    :isOpen="!!courseId"
    persistent
  >
    <template v-slot:title>
      <h3 class="headline">
        {{ courseId === 'create' ? 'Nowe szkolenie' : 'Edycja szkolenia' }}
      </h3>
    </template>
    <template v-slot:content>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col :cols="12" sm="8">
              <v-text-field
                v-model="form.title"
                label="Nazwa"
                :rules="rules.title"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col :cols="12" sm="4">
              <v-text-field
                v-model="form.redtrainId"
                label="RedTrain Id"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col :cols="12" sm="6">
              <DatePicker
                v-model="form.date"
                label="Data"
                :rules="rules.date"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <TimePicker
                v-model="form.time"
                class="w-100"
                label="Czas"
                :rules="rules.time"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Trener"
                v-model="form.coachId"
                :items="coaches"
                item-text="name"
                item-value="id"
                :loading="coachesLoading"
                :rules="rules.coachId"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Wzór ankiety"
                v-model="form.questionnaireId"
                :items="questionnaires"
                item-text="name"
                item-value="id"
                :loading="questionnairesLoading"
                :rules="rules.questionnaireId"
                clearable
            />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="form.address"
                label="Adres"
                :rules="rules.address"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="Nr sklepu"
                v-model="form.shopNumber"
                :rules="rules.shopNumber"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Firma"
                v-model="form.header"
                :rules="rules.header"
                clearable
                :loading="fetchLoading"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <div class="mb-2">
                <span class="theme--light field-label">
                  Logo
                </span>
                <div class="d-flex">
                  <FileUploadButton
                    @change="prepareImage"
                    class="mr-2"
                  >
                    Wybierz plik
                  </FileUploadButton>
                  <v-btn
                    small
                    rounded
                    color="error"
                    :disabled="!logo"
                    @click="removeLogo"
                  >
                    Usuń
                  </v-btn>
                </div>
              </div>
              <Loading :value="logoLoading"/>
              <v-img
                v-if="logo"
                :src="logo"
                position="top left"
                contain
                max-height="100"
              />
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <p>Wybierz punkt na mapie:</p>
                <v-btn
                  small
                  color="info"
                  :loading="addressSearching"
                  @click="searchAddress"
                >
                  Szukaj adres
                </v-btn>
              </div>
              <div>
                <v-chip
                  v-for="address in addressesList"
                  :key="address.place_id"
                  small
                  :color="form.location[0] === Number(address.lat) && form.location[1] === Number(address.lon) ? 'success' : ''"
                  class="mr-2 mb-2 max-w-100 pointer"
                  :title="address.display_name"
                  @click="setAddress(address.lat, address.lon)"
                >
                  <div class="cut-text">
                    {{address.display_name}}
                  </div>
                </v-chip>
              </div>
              <Map
                v-model="form.location"
                draggableMarker
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn color="primary" :loading="saveLoading" text @click="save">
        Zapisz
      </v-btn>
      <v-btn color="info" :disabled="saveLoading" text @click="close">
        Anuluj
      </v-btn>
    </template>
  </ModalTemplate>
</template>

<script>
import ModalTemplate from "@/templates/ModalTemplate";
import DatePicker from "@/components/pickers/DatePicker";
import TimePicker from "@/components/pickers/TimePicker";
import {required} from "@/utils/validationRules";
import Map from "@/components/Map";
import {notify, notifyStandardError} from "@/services/notificationService";
import {fetchCourseToEdit, fetchLogo, removeLogo, saveCourse, searchAddress} from "@/services/coursesService";
import {fetchCoaches} from "@/services/coachesService";
import {clone} from "@/utils/dataTransformation";
import {fetchQuestionnaires} from "@/services/questionnaireService";
import {toBase64} from "@/utils/file";
import FileUploadButton from "@/components/FileUploadButton";
import Loading from "@/components/Loading";
import {hasPermissions} from "@/utils/permission";

const emptyForm = {
  title: '',
  date: null,
  time: null,
  address: '',
  location: null,
  shopNumber: null,
  coachId: null,
  questionnaireId: null,
  redtrainId: null,
  header: '',
  logoPath: ''
}

export default {
  name: 'CourseForm',
  components: {Loading, FileUploadButton, Map, TimePicker, DatePicker, ModalTemplate},
  props: {
    courseId: [String, Number]
  },
  data: () => ({
    addressSearching: false,
    addressesList: [],
    shouldRemoveLogo: false,
    logo: null,
    logoLoading: false,
    fetchLoading: false,
    saveLoading: false,
    coachesLoading: false,
    questionnairesLoading: false,
    coaches: [],
    questionnaires: [],
    rules: {
      title: [required],
      date: [required],
      time: [required],
      address: [required],
      location: [],
      shopNumber: [required],
      coachId: [required],
      questionnaireId: [required],
      header: [required],
      logo: [required]
    },
    form: clone(emptyForm)
  }),
  computed: {
    isCreateMode() {
      return this.courseId === 'create'
    }
  },
  async beforeMount() {
    if (!this.isCreateMode) {
      await this.fetchCourse()
      this.fetchLogo()
    }
    this.fetchCoachesList()
    this.fetchQuestionnairesList()
  },
  methods: {
    hasPermissions(permissions) {
      return hasPermissions(permissions)
    },
    removeLogo() {
      this.shouldRemoveLogo = !!this.form.logoPath
      this.logo = null
    },
    async prepareImage(file) {
      this.shouldRemoveLogo = false
      if (file) {
        this.logo = await toBase64(file)
      }
    },
    setAddress(lat, lon) {
      this.form.location = [Number(lat), Number(lon)]
    },
    async searchAddress() {
      this.addressSearching = true
      try {
        this.addressesList = await searchAddress(this.form.address)
        if (this.addressesList.length) {
          this.setAddress(Number(this.addressesList[0].lat), Number(this.addressesList[0].lon))
        } else {
          notify('error', 'Nie udało się znaleść adresu', 'Wybierz punkt na mapie ręcznie')
        }
      } catch (e) {
        notifyStandardError()
      } finally {
        this.addressSearching = false
      }
    },
    async save() {
      if (!this.isCreateMode && !this.hasPermissions(['training.update'])) {
        notify('error', 'Brak uprawnień do edycji')
        return
      }
      if (this.$refs.form.validate()) {
        try {
          this.saveLoading = true
          await saveCourse({...this.form, logo: this.logo}, !this.isCreateMode ? this.courseId : null)
          if (this.shouldRemoveLogo) {
            await removeLogo(this.courseId)
          }
          this.emitRefresh()
          this.close()
          notify('success', `Szkolenie zostało ${this.isCreateMode ? 'stworzone' : 'zmodyfikowane'}`)
        } catch (e) {
          notifyStandardError()
        } finally {
          this.saveLoading = false
        }
      }
    },
    async fetchLogo() {
      try {
        this.logoLoading = true
        if (this.form.logoPath) {
          this.logo = await fetchLogo(this.form.logoPath)
        }
      } catch (e) {
        notify('error', 'Nie udało się pobrać logo')
      } finally {
        this.logoLoading = false
      }
    },
    emitRefresh() {
      this.$emit('refreshList')
    },
    close() {
      this.$refs.form?.resetValidation()
      this.$emit('close')
    },
    async fetchCourse() {
      try {
        this.fetchLoading = true
        this.form = await fetchCourseToEdit(this.courseId)
      } catch (e) {
        notifyStandardError()
      } finally {
        this.fetchLoading = false
      }
    },
    async fetchCoachesList() {
      try {
        this.coachesLoading = true
        this.coaches = await fetchCoaches()
      } catch (e) {
        notify('error', 'Błąd pobierania listy trenerów')
      } finally {
        this.coachesLoading = false
      }
    },
    async fetchQuestionnairesList() {
      try {
        this.questionnairesLoading = true
        const {data} = await fetchQuestionnaires({perPage: 1000000})
        this.questionnaires = data
      } catch (e) {
        notify('error', 'Błąd pobierania listy ankiet')
      } finally {
        this.questionnairesLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .row > * {
    padding: 0 6px;
  }
  .field-label {
    font-size: 12px;
  }
</style>
