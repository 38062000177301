<template>
  <l-map
    v-if="isMapVisible"
    :style="`height: ${height}; width: 100%`"
    :zoom="zoom"
    :center="(value && value.length) ? value : center"
    :options="mapOptions"
    @click="onMapClicked"
  >
    <l-tile-layer :url="url"/>
    <l-marker
      v-if="value && value.length"
      :draggable="draggableMarker"
      :lat-lng="value"
      @update:latLng="onMarkerDragged"
    />
  </l-map>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {latLng} from "leaflet";
import {Icon} from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    value: {
      type: Array,
    },
    height: {
      type: String,
      default: '300px'
    },
    draggableMarker: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    zoom: 13,
    center: latLng(50.04087995683378, 22.00482559623197),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    mapOptions: {
      zoomSnap: 0.5
    },
    isMapVisible: true
  }),
  mounted() {
    // this code fixes strange Leaflet Map bug connected with loading map images and setting center
    this.isMapVisible = false
    setTimeout(() => {
      this.isMapVisible = true
    }, 1)
  },
  methods: {
    onMapClicked(point) {
      this.$emit('input', [point.latlng.lat, point.latlng.lng])
    },
    onMarkerDragged(point) {
      this.$emit('input', [point.lat, point.lng])
    },
  }
}
</script>

<style scoped>

</style>
