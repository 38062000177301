<template>
  <v-card>
    <v-card-text>
      <div class="d-flex align-start flex-column h-100">
        <div class="mb-auto w-100">
          <p class="question-text">
            {{ number }}. {{ question }}
          </p>
        </div>
        <div>
          <v-btn
            small
            readonly
            class="disabled"
            :color="answer ? 'primary' : null"
          >
            Tak
          </v-btn>
          <v-btn
            small
            readonly
            :color="!answer ? 'primary' : null"
            class="disabled ml-5"
          >
            Nie
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'QuestionWithAnswer',
  props: {
    question: String,
    answer: Boolean,
    number: Number
  }
}
</script>

<style scoped>

</style>
