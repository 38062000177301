<template>
  <v-menu
    v-model="isOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        readonly
        :value="value"
        :label="label"
        :rules="rules"
        persistent-hint
        v-bind="{...$attrs, ...attrs}"
        @click:clear="onDateSelect('')"
        v-on="on"
      >
        <template v-slot:append>
          <v-icon v-on="on">
            mdi-calendar
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :value="value"
      :type="type"
      no-title
      color="primary"
      v-bind="{...$attrs}"
      @input="onDateSelect"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    label: String,
    value: {
      type: [String, Date, null]
    },
    rules: Array,
    type: String
  },
  data: () => ({
    isOpen: false
  }),
  methods: {
    onDateSelect(value) {
      this.isOpen = false
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
