<template>
  <div>
    <v-btn
      color="primary"
      rounded
      small
      depressed
      :loading="isSelecting"
      @click="onButtonClick"
    >
      <slot />
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      @change="onFileChanged"
    >
  </div>
</template>

<script>
export default {
  name: 'FileUploadButton',
  props: {
    accept: {
      type: String,
      default: 'image/*'
    }
  },
  data: () => ({
    isSelecting: false
  }),
  methods: {
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.$emit('change', e.target.files[0])
    }
  }
}
</script>

<style scoped>

</style>
