<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="loading"
    class="elevation-0 row-pointer"
    :server-items-length="pagination.totalElements"
    :footer-props="{'items-per-page-options':[10, 30, 50, 100]}"
    @update:options="optionsUpdated"
    @click:row="rowClick"
  >
    <template v-slot:item.actions="{ item }">
      <v-btn
        v-if="item.canRollbackStatus && hasPermissions(['training.rollback_status'])"
        color="green"
        icon
        @click.stop="rollbackStatus(item.id)"
      >
        <v-icon size="18">
          mdi-backup-restore
        </v-icon>
      </v-btn>
      <v-btn
        color="success"
        icon
        :loading="item.isDownloading"
        @click.stop="downloadDocument(item)"
      >
        <v-icon size="18">
          mdi-download
        </v-icon>
      </v-btn>
      <v-btn
        v-if="item.canUpdate && hasPermissions(['training.update'])"
        color="primary"
        icon
        @click.stop="edit(item.id)"
      >
        <v-icon size="18">
          mdi-square-edit-outline
        </v-icon>
      </v-btn>
      <v-btn
        v-if="item.canDelete && hasPermissions(['training.delete'])"
        color="red"
        icon
        @click.stop="remove(item.id)"
      >
        <v-icon size="18">
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>

import {downloadDocument} from "@/services/coursesService";
import {notify} from "@/services/notificationService";
import {hasPermissions} from "@/utils/permission";

export default {
  name: 'CoursesList',
  props: {
    data: Array,
    loading: Boolean,
    pagination: {
      default: null
    }
  },
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id',
        sortable: true,
      },
      {
        text: 'Nazwa',
        value: 'name',
        sortable: true,
      },
      {
        text: 'Data i czas',
        value: 'startedAt',
        sortable: true,
      },
      {
        text: 'Adres',
        value: 'address',
        sortable: true,
      },
      {
        text: 'Nr sklepu',
        value: 'shopNo',
        sortable: true,
      },
      {
        text: 'Trener',
        value: 'trainer',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        sortable: true,
      },
      {
        text: 'RedTrain Id',
        value: 'redtrainId',
        sortable: true,
      },
      {
        text: 'Akcje',
        value: 'actions',
        sortable: false,
        align: 'end'
      },
    ],
  }),
  methods: {
    hasPermissions(permissions) {
      return hasPermissions(permissions)
    },
    async downloadDocument(course) {
      try {
        this.$emit('download', course.id, true)
        await downloadDocument(course)
      } catch (e) {
        notify('error', 'Błąd pobierania', 'Sprobój jeszcze raz')
      } finally {
        this.$emit('download', course.id, false)
      }
    },
    rowClick(row) {
      this.$emit('rowClick', row)
    },
    rollbackStatus(id) {
      this.$emit('rollbackStatus', id)
    },
    remove(id) {
      this.$emit('remove', id)
    },
    edit(id) {
      this.$emit('edit', id)
    },
    refreshData() {
      this.$emit('fetchData')
    },
    optionsUpdated({page, itemsPerPage, sortBy, sortDesc}) {
      this.pagination.page = page
      this.pagination.perPage = itemsPerPage
      this.pagination.sortBy = sortBy[0]
      this.pagination.orderBy = sortDesc[0] ? 'desc' : 'asc'

      this.refreshData()
    },
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
