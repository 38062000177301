import axios from "axios";
import {
  camelToSnakeCase,
  everyPropKey,
  snakeToCamelCase,
  staySelectedProps,
  utcDateTimeToStandardFormat
} from "@/utils/dataTransformation";
import {prepareList} from "@/services/apiService";
import {downloadFile, toBase64} from "@/utils/file";

export const fetchCourses = async (onlyArchived, pagination) => {
  const preparedPagination = everyPropKey(pagination, camelToSnakeCase)
  if (preparedPagination.sort_by) {
    preparedPagination.sort_by = camelToSnakeCase(preparedPagination.sort_by)
  }
  
  const {data} = await axios.post('trainings', {
    ...preparedPagination,
    archive: onlyArchived
  })
  const preparedData = prepareList(data.data, snakeToCamelCase, staySelectedProps)([
    'id',
    'name',
    'startedAt',
    'address',
    'shopNo',
    'trainer',
    'status',
    'canRollbackStatus',
    'canUpdate',
    'canDelete',
    'redtrainId'
  ], '').map(item => ({
    ...item,
    trainer: `${item?.trainer?.first_name || ''} ${item?.trainer?.last_name || ''}`,
    startedAt: utcDateTimeToStandardFormat(item.startedAt)
  }))
  
  return {
    data: preparedData,
    totalElements: data.total
  }
}

export const searchAddress = async (address) => {
  const {data} = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
  
  return data || []
}

export const saveCourse = async (course, id = null) => {
  const data = {
    name: course.title,
    started_at: `${course.date} ${course.time}`,
    address: course.address,
    lat: course.location[0],
    lng: course.location[1],
    shop_no: course.shopNumber,
    trainer_id: course.coachId,
    poll_id: course.questionnaireId,
    redtrain_id: course.redtrainId,
    header: course.header,
    logo: course.logo
  }
  const method = id ? 'put' : 'post'
  const path = id ? `training/${id}` : 'training'
 
  await axios[method](path, data)
}

export const fetchCourseToEdit = async (id) => {
  const {data} = await axios.get(`training/${id}`)
  const camelCasedObj = everyPropKey(data, snakeToCamelCase)
  
  const cutObj = staySelectedProps(camelCasedObj, [
    'address',
    'lat',
    'lng',
    ['name', '', 'title'],
    ['pollId', null, 'questionnaireId'],
    ['shopNo', '', 'shopNumber'],
    'startedAt',
    'status',
    ['trainerId', null, 'coachId'],
    'redtrainId',
    'header',
    ['logo', null, 'logoPath']
  ], '')
  cutObj.location = [cutObj.lat, cutObj.lng]
  const dateTime = utcDateTimeToStandardFormat(cutObj?.startedAt)
  cutObj.date = dateTime.split(' ')[0]
  cutObj.time = dateTime.split(' ')[1]
  
  return cutObj
}

export const fetchCourseToShow = async (id) => {
  const {data} = await axios.get(`training/${id}`)
  const camelCasedObj = everyPropKey(data, snakeToCamelCase)
  
  const cutObj = staySelectedProps(camelCasedObj, [
    'address',
    'archived',
    'attendees',
    'canRollbackStatus',
    'lat',
    'lng',
    ['name', '', 'title'],
    ['questionsWithAnswers', []],
    ['shopNo', '', 'shopNumber'],
    'status',
    'startedAt',
    ['trainer', null, 'coach'],
    ['poll', {}, 'questionnaire'],
    'redtrainId',
    'header',
    ['logo', null, 'logoPath']
  ], '')
  cutObj.location = [cutObj.lat, cutObj.lng]
  cutObj.datetime = utcDateTimeToStandardFormat(cutObj?.startedAt)
  
  return cutObj
}

export const fetchLogo = async (path) => {
  try {
    const {data} = await axios.get(`training/logo/${path}`, {
      responseType: 'blob',
    })
    return toBase64(data)
  } catch (e) {
    throw new Error()
  }
}

export const removeLogo = (courseId) => {
  return axios.delete(`/training/logo/${courseId}`)
}

export const fetchAttendeeSign = async (path) => {
  return axios.get(`training/attendee/${path}`, {
    responseType: 'blob',
  })
}

export const rollbackCourseStatus = async (id) => {
  await axios.put(`training/${id}/rollback-status`)
}

export const removeCourse = async (id) => {
  await axios.delete(`training/${id}`)
}

export const fetchDocument = (courseId) => {
  return axios.get(`/training/${courseId}/pdf`, {responseType: 'blob'})
}

export const downloadDocument = async (course) => {
  const response = await fetchDocument(course.id)
  const filename = `${course.name}_${course.id}`
  downloadFile(response.data, filename)
}

export const archiveCourse = async (id) => {
  return axios.post(`archive/${id}`)
}
export const unarchiveCourse = async (id) => {
  return axios.delete(`archive/${id}`)
}
