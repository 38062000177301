import {hasPermissions} from "@/utils/permission";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState('auth', ['isAdmin'])
  },
  methods: {
    hasPermissions(permissions) {
      return hasPermissions(permissions)
    },
  }
}