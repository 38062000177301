<template>
  <v-container>
    <v-row v-if="questions.length">
      <v-col
        cols="12"
        sm="6"
        v-for="(question, idx) in questions"
        :key="question.key"
        class="h-100"
      >
        <QuestionWithAnswer
          :question="question.text"
          :answer="question.answer"
          :number="idx + 1"
        />
      </v-col>
    </v-row>
    <NoData v-else />
  </v-container>
</template>

<script>
import QuestionWithAnswer from "@/components/courses/showCourseDetails/QuestionWithAnswer";
import NoData from "@/components/NoData";

export default {
  name: 'QuestionnaireWithAnswers',
  components: {NoData, QuestionWithAnswer},
  props: {
    questions: Array,
    questionnaireName: String
  },

}
</script>

<style scoped>

</style>
