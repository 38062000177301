import store from '@/store/index'

export const isAdmin = () => {
  return store.state['auth/isAdmin']
}

// default is OR condition
export const hasPermissions = (permissions, andCondition = false) => {
  if (isAdmin()) {
    return true
  }
  const userPermissions = store.getters['auth/getPermissions']
  if (andCondition) {
    let counter = 0
    permissions.forEach(permission => {
      const result = userPermissions.some(item => item.permission === permission)
      if (result) {
        counter++
      }
    })

    return counter === permissions.length
  } else {
    return permissions.some(permission => {
      return userPermissions.some(item => item.permission === permission)
    })
  }
}
