<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <TextInfo
          label="Data i czas"
          :value="data.datetime"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <TextInfo
          label="RedTrain Id"
          :value="data.redtrainId"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <TextInfo
          label="Trener"
          :value="coach"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <TextInfo
          label="Wzór ankiety"
          :value="questionnaire"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <TextInfo
          label="Adres"
          :value="data.address"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <TextInfo
          label="Nr sklepu"
          :value="data.shopNumber"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <TextInfo
          label="Firma"
          :value="data.header"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <TextInfo label="Logo"/>
        <Loading :value="data.logo === 'loading'"/>
        <v-img
          position="top left"
          v-if="data.logo && data.logo !== 'loading'"
          :src="data.logo"
          contain
          max-height="100"
        />
      </v-col>
      <v-col cols="12">
        <Map
          :value="data.location"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextInfo from "@/components/TextInfo";
import Map from "@/components/Map";
import Loading from "@/components/Loading";
export default {
  name: "CourseInfo",
  components: {Loading, Map, TextInfo},
  props: {
    data: Object
  },
  computed: {
    coach() {
      const firstName = this.data?.coach?.first_name || ''
      const lastName = this.data?.coach?.last_name || ''

      return `${firstName} ${lastName}`
    },
    questionnaire() {
      return this.data?.questionnaire?.name || ''
    }
  },
}
</script>

<style scoped>

</style>
