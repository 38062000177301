<template>
  <div>
    <ModalTemplate
      :isOpen="!!courseId"
      @closed="close"
      persistent
    >
      <template v-slot:title>
        <h3 class="headline">
          {{ data.title }}
        </h3>
      </template>
      <template v-slot:content>
        <v-tabs
          v-model="tab"
          grow
          show-arrows
        >
          <v-tab
            v-for="item in tabs"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <Loading
          :value="loading"
          class="mt-5"
        />
        <v-tabs-items v-if="!loading" v-model="tab">
          <v-tab-item>
            <CourseInfo
              :data="data"
            />
          </v-tab-item>
          <v-tab-item>
            <QuestionnaireWithAnswers
              :questionnaireName="questionnaireName"
              :questions="data.questionsWithAnswers"
            />
          </v-tab-item>
          <v-tab-item>
            <AttendeesList
              :attendees="data.attendees"
            />
          </v-tab-item>
        </v-tabs-items>

      </template>
      <template v-slot:footer>
        <v-btn
          v-if="isAdmin || hasPermissions(['archive.update'])"
          color="info"
          @click="isArchiveConfirmationModalOpen = true"
        >
          {{data.archived ? 'Aktywuj' : 'Archiwizuj'}}
        </v-btn>
        <v-btn color="info" text @click="close">
          Zamknij
        </v-btn>
      </template>
    </ModalTemplate>
    <ConfirmationDialog
      :value="isArchiveConfirmationModalOpen"
      :loading="isTogglingArchveStatus"
      :text="!!data.archived ? 'Przywrócić z archiwum?' : 'Archiwizować?'"
      @confirm="toggleArchiveStatus"
      @deny="isArchiveConfirmationModalOpen = false"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import ModalTemplate from "@/templates/ModalTemplate";
import {archiveCourse, fetchCourseToShow, fetchLogo, unarchiveCourse} from "@/services/coursesService";
import {notify, notifyStandardError} from "@/services/notificationService";
import CourseInfo from "@/components/courses/showCourseDetails/CourseInfo";
import QuestionnaireWithAnswers from "@/components/courses/showCourseDetails/QuestionnaireWithAnswers";
import AttendeesList from "@/components/courses/showCourseDetails/AttendeesList";
import Loading from "@/components/Loading";
import permissions from "@/mixins/permissions";

export default {
  name: 'ShowCourse',
  components: {
    ConfirmationDialog: () => import('@/components/system/ConfirmationDialog'),
    Loading,
    AttendeesList,
    QuestionnaireWithAnswers,
    CourseInfo,
    ModalTemplate
  },
  mixins: [permissions],
  props: {
    courseId: [String, Number]
  },
  data: () => ({
    data: {},
    loading: false,
    tab: null,
    tabs: [
      'Informacja', 'Wyniki ankiety', 'Lista podpisów'
    ],
    isTogglingArchveStatus: false,
    isArchiveConfirmationModalOpen: false,
    wasChangedArchiveStatus: false
  }),
  async beforeMount() {
    await this.fetchCourse()
    await this.fetchLogo()
  },
  computed: {
    questionnaireName() {
      return this.data?.questionnaire?.name || ''
    }
  },
  methods: {
    async toggleArchiveStatus() {
      this.isTogglingArchveStatus = true
      try {
        if (this.data?.archived) {
          await unarchiveCourse(this.courseId)
        } else {
          await archiveCourse(this.courseId)
        }
        await this.fetchCourse()
        this.wasChangedArchiveStatus = true
      } catch (e) {
        notify('error', 'Nie udało się zarchiwizować')
      } finally {
        this.isTogglingArchveStatus = false
        this.isArchiveConfirmationModalOpen = false
      }
    },
    async fetchLogo() {
      try {
        if (this.data.logoPath) {
          Vue.set(this.data, 'logo', 'loading')
          // this.data.logo = 'loading'
          Vue.set(this.data, 'logo', await fetchLogo(this.data.logoPath))
          // this.data.logo = await fetchLogo(this.data.logoPath)
        }
      } catch (e) {
        notify('error', 'Nie udało się pobrać logo')
      }
    },
    async fetchCourse() {
      try {
        this.loading = true
        this.data = await fetchCourseToShow(this.courseId)
      } catch (e) {
        notifyStandardError()
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$emit('close', this.wasChangedArchiveStatus)
    }
  }
}
</script>

<style scoped>

</style>
